<template>
  <div>
    <div class="row no-gutters">
      <div class="col-sm-8 pt-3 pl-3 pb-5">
        <div class="row">
          <div class="col-sm-12">
            <h6 class="mb-2">
              Select a Report with required data elements.
              <span class="text-muted">
                (New Reports can also be created using the button below.)
              </span>
            </h6>
          </div>
        </div>

        <b-card-group deck>
          <b-card
            class="std-border std-list-card"
            body-class="p-0"
            header-class="pl-3"
          >
            <div slot="header">
              <h6 class="mb-0"><b>My Custom Reports</b></h6>
            </div>
            <v-wait for="loadingUserReports">
              <template slot="waiting">
                <content-placeholders :rounded="true">
                  <content-placeholders-text
                    :lines="15"
                  ></content-placeholders-text>
                </content-placeholders>
              </template>
              <b-list-group class="selectable-list-group">
                <div v-if="filteredUserCustomReports.length">
                  <b-list-group-item
                    v-for="(report, index) in filteredUserCustomReports"
                    :key="index"
                    :class="[
                      selectedReport && selectedReport.id === report.id
                        ? 'selected'
                        : '',
                      'p-2'
                    ]"
                    @click="selectReport(report)"
                  >
                    <span :id="`ur-${report.id}`">
                      {{ getCustomizedName(report.name) }}
                      <span class="text-muted" v-if="isFinApiEnterpriseUser">
                        [{{ report.id }}]
                      </span>
                      <span
                        class="text-muted"
                        v-if="report.group_ids && report.group_ids.length"
                      >
                        <b-badge pill variant="secondary" class="px-1"
                          >Shared
                        </b-badge>
                      </span>
                    </span>
                    <span
                      class="p-0 pl-1 pb-1  favorite__star__selected"
                      v-if="report.category === 'key_metrics'"
                      ><b> &#x2605;</b></span
                    >
                    <span class="manage--report-group">
                      <button
                        type="submit"
                        class=" pt-0 float-right btn btn-secondary btn-sm mx-1"
                        @click.stop="deleteReport(report)"
                      >
                        Delete
                      </button>
                      <button
                        type="submit"
                        class="pt-0 float-right btn btn-primary btn-sm mx-1"
                        @click.stop="editReport(report)"
                      >
                        Edit
                      </button>
                    </span>
                    <b-popover
                      :target="`ur-${report.id}`"
                      triggers="hover"
                      placement="left"
                      v-if="report.description"
                    >
                      {{ report.description }}
                    </b-popover>
                  </b-list-group-item>
                </div>
                <div v-else>
                  <b-list-group-item class="text-center">
                    <i>No Reports found</i>
                  </b-list-group-item>
                </div>
              </b-list-group>
            </v-wait>
          </b-card>

          <b-card
            class="std-border reports-card std-list-card"
            body-class="p-1"
            header-class="pl-3"
          >
            <div slot="header">
              <h6 class="mb-0"><b>FI Navigator Reports</b></h6>
            </div>
            <v-wait for="loadingFINNavigatorReports">
              <template slot="waiting">
                <content-placeholders :rounded="true">
                  <content-placeholders-text
                    :lines="15"
                  ></content-placeholders-text>
                </content-placeholders>
              </template>
              <b-list-group class="selectable-list-group">
                <div v-if="filteredNavigatorReports.length">
                  <b-list-group-item
                    v-for="(navigatorReport, index) in filteredNavigatorReports"
                    :key="index"
                    :class="[
                      selectedReport && selectedReport.id === navigatorReport.id
                        ? 'selected'
                        : '',
                      'p-2'
                    ]"
                    @click="selectReport(navigatorReport)"
                  >
                    <span :id="`ng-${navigatorReport.id}`">
                      {{ navigatorReport.name }}
                    </span>
                    <b-popover
                      :target="`ng-${navigatorReport.id}`"
                      triggers="hover"
                      placement="left"
                      v-if="navigatorReport.description"
                    >
                      {{ navigatorReport.description }}
                    </b-popover>
                  </b-list-group-item>
                </div>
                <div v-else>
                  <b-list-group-item class="text-center">
                    <i>No FI Navigator Reports found</i>
                  </b-list-group-item>
                </div>
              </b-list-group>
            </v-wait>
          </b-card>
        </b-card-group>

        <div class="row text-center pt-2">
          <div class="col-sm-12">
            <b-button
              size=""
              variant="secondary"
              class="fw-btn mt-3 mx-3 px-2"
              @click="resetSelections"
              >Reset</b-button
            >
            <b-button
              size=""
              variant="primary"
              class="fw-btn mt-3 mx-3 px-2"
              @click="createReport"
              >Create Report</b-button
            >
            <router-link
              tag="button"
              :to="{ path: 'report' }"
              class="btn btn-primary fw-btn mt-3 mx-3"
              :disabled="!canContinue"
            >
              Continue
            </router-link>
          </div>
        </div>

        <b-row v-if="selectedReport" class="pt-2">
          <b-col md="12">
            <b-card
              class="std-border metrics-card mt-4"
              body-class="p-1"
              header-class="pl-3"
            >
              <div slot="header">
                <h6 class="mb-0">
                  <b>{{ selectedReport.name }} - Report Metrics</b>
                </h6>
              </div>
              <v-wait for="metricsLoading">
                <template slot="waiting">
                  <content-placeholders :rounded="true">
                    <content-placeholders-text
                      :lines="15"
                    ></content-placeholders-text>
                  </content-placeholders>
                </template>
                <div v-if="reportMetrics.length">
                  <b-list-group class="selectable-list-group">
                    <b-list-group-item
                      v-for="(metric, index) in reportMetrics"
                      :key="index"
                      class="p-2"
                    >
                      {{ metric.name }}
                    </b-list-group-item>
                  </b-list-group>
                </div>
                <div v-else>
                  <b-list-group class="selectable-list-group">
                    <b-list-group-item class="p-3 text-center">
                      No report metrics found.
                    </b-list-group-item>
                  </b-list-group>
                </div>
              </v-wait>
            </b-card>
          </b-col>
        </b-row>
      </div>

      <div class="offset-sm-1 col-sm-3 px-0 std-border">
        <b-card class="" header-class="p-2" body-class="px-3 pt-3">
          <div slot="header">
            <div class="d-flex align-items-center">
              <div>
                <i class="cuis-filter card-icon-font"></i>
              </div>
              <div class="px-2">
                <h6 class="mb-0">
                  <b>Report Filters</b>
                </h6>
                <div class="text-muted">
                  Select options to filter reports.
                </div>
              </div>
            </div>
          </div>
          <div class="font-weight-bold mb-1">
            Report Categories:
          </div>
          <div class="report-output-view">
            <multiselect
              track-by="value"
              label="text"
              v-model="filters"
              :allowEmpty="true"
              :showLabels="false"
              :options="filterOptions"
              class="rw-multi-select aq-multiselect"
              placeholder="Apply Filters"
              :multiple="true"
              :close-on-select="false"
              group-label="label"
              group-values="options"
              :group-select="false"
            ></multiselect>
          </div>
        </b-card>
      </div>

      <report-writer-modal @reportModalClosed="reportClosed">
      </report-writer-modal>
    </div>
  </div>
</template>

<script>
// global
import { mapState, mapGetters, mapMutations } from 'vuex'
//ui components
import ReportWriterModal from '@/modules/report_writer/components/ReportWriterModal'
import Multiselect from 'vue-multiselect'
//api
import selfServeReportsAPI from '@/api/finapps/self_serve_reports'

export default {
  name: 'IRSelectReport',
  components: {
    Multiselect,
    ReportWriterModal
  },
  data() {
    return {
      filters: [],
      reportMetrics: [],
      userCustomReports: [],
      finNavigatorReports: [],
      filterOptions: [
        {
          label: 'Please select',
          options: [
            { value: 'firmographics', text: 'Firmographics' },
            { value: 'technographics', text: 'Technographics' },
            { value: 'other', text: 'Other/Unassigned' }
          ]
        },
        {
          label: 'Financial Data',
          options: [
            { value: 'performographics', text: 'Performographics' },
            {
              value: 'common-metrics',
              text: 'Common Metrics (Bank/CU)'
            },
            {
              value: 'detailed-metrics',
              text: 'Detailed Metrics'
            },
            {
              value: 'financial-statements',
              text: 'Common Statements (Bank/CU)'
            },
            {
              value: 'detailed-statements',
              text: 'Detailed Statements'
            }
          ]
        }
        // {
        //   label: 'Financial Metrics',
        //   options: [
        //     { value: 'profit', text: 'Profit' },
        //     { value: 'growth', text: 'Growth' },
        //     { value: 'risk', text: 'Risk' }
        //   ]
        // },
        // {
        //   label: 'Financial Statements',
        //   options: [
        //     { value: 'balance_sheet', text: 'Balance Sheet' },
        //     { value: 'income_statement', text: 'Income Statement' },
        //     { value: 'deposit_details', text: 'Deposit Details' },
        //     { value: 'credit_quality', text: 'Credit Quality' }
        //   ]
        // },
        // {
        //   label: 'Technographics',
        //   options: [
        //     {
        //       value: 'retail_services',
        //       text: 'Retail Services'
        //     },
        //     {
        //       value: 'business_services',
        //       text: 'Business Services'
        //     },
        //     {
        //       value: 'operating_solutions',
        //       text: 'Operating Solutions'
        //     }
        //   ]
        // }
      ]
    }
  },
  created() {
    if (this.selectedGroups.length) {
      this.loadInitData()
    } else {
      this.$toasted.global.invalid('Please select FI Group.')
      this.$router.push('select_fi_group')
    }
  },
  computed: {
    ...mapState('IndustryReporter', {
      groupFIType: state => state.groupFIType,
      selectedGroups: state => state.selectedGroups,
      selectedReport: state => state.selectedReport
    }),
    ...mapGetters('Ability', ['isFinApiEnterpriseUser']),
    canContinue() {
      return this.selectedReport
    },
    appliedTags() {
      return this.filters.map(filter => filter.value)
    },
    filteredUserCustomReports() {
      return this.userCustomReports
        .filter(cr => {
          if (this.appliedTags.length) {
            return (
              [this.groupFIType.value].includes(cr.fi_type) &&
              !cr.is_public &&
              cr.taggings.some(tag => this.appliedTags.includes(tag))
            )
          } else {
            return (
              [this.groupFIType.value].includes(cr.fi_type) && !cr.is_public
            )
          }
        })
        .sort((a, b) => (a.name.toLowerCase() > b.name.toLowerCase() ? 1 : -1))
    },
    filteredNavigatorReports() {
      return this.finNavigatorReports.filter(nr => {
        if (this.appliedTags.length) {
          return (
            [this.groupFIType.value].includes(nr.fi_type) &&
            nr.taggings.some(tag => this.appliedTags.includes(tag))
          )
        } else {
          return [this.groupFIType.value].includes(nr.fi_type)
        }
      })
    }
  },
  methods: {
    ...mapMutations('IndustryReporter', ['setSelectedReport']),
    ...mapMutations('ReportWriter', [
      'setReportFIType',
      'setCustomReport',
      'clearSelectedReport',
      'setReportActualFIType'
    ]),
    getCustomizedName(name) {
      return name.length > 25 ? name.substring(0, 24) + '..' : name
    },
    loadInitData() {
      this.loadUserCustomReports()
      this.loadFINNavigatorReports()
      this.loadReportMetrics()
    },
    loadUserCustomReports() {
      this.$wait.start('loadingUserReports')
      selfServeReportsAPI
        .userReports({ industry_reporter: true })
        .then(res => {
          this.userCustomReports = res.custom_reports
        })
        .finally(() => {
          this.$wait.end('loadingUserReports')
        })
    },
    loadFINNavigatorReports() {
      this.$wait.start('loadingFINNavigatorReports')
      selfServeReportsAPI
        .navigatorReports()
        .then(res => {
          this.finNavigatorReports = res.navigator_reports
        })
        .finally(() => {
          this.$wait.end('loadingFINNavigatorReports')
        })
    },
    selectReport(report) {
      this.setSelectedReport(report)
      this.loadReportMetrics()
    },
    removeReport(report) {
      let reportIndex = this.userCustomReports.findIndex(
        ur => ur.id === report.id
      )
      this.userCustomReports.splice(reportIndex, 1)
      this.setSelectedReport(null)
    },
    reportClosed() {
      this.loadUserCustomReports()
      this.loadFINNavigatorReports()
      this.loadReportMetrics()
    },
    createReport() {
      this.clearSelectedReport()
      this.setReportFIType(this.groupFIType.value)
      this.setSelectedReport(null)
      this.$bvModal.show('irReportWriterModal')
    },
    editReport(report) {
      this.setSelectedReport(report)
      this.loadReportMetrics()
      this.setCustomReport(report)
      this.setReportActualFIType(report.fi_type)
      this.$bvModal.show('irReportWriterModal')
    },
    deleteReport(report) {
      return this.$dialog
        .confirm(
          {
            title: 'Alert',
            body: `Are you sure, you want to delete Report - ${report.name} ?`
          },
          {
            okText: 'Delete',
            cancelText: 'Cancel'
          }
        )
        .then(() => {
          selfServeReportsAPI.delete(report.id).then(() => {
            this.removeReport(report)
            this.$toasted.global.action_success('Report successfully deleted.')
          })
        })
        .catch(() => {})
    },
    loadReportMetrics: function() {
      if (this.selectedReport) {
        this.$wait.start('metricsLoading')

        selfServeReportsAPI
          .metricsList(this.selectedReport.id, { industry_reporter: true })
          .then(res => {
            this.reportMetrics = res.metrics
            this.$wait.end('metricsLoading')
          })
      }
    },
    resetSelections() {
      this.filters = []
      this.reportMetrics = []
      this.setSelectedReport(null)
    }
  },
  watch: {
    filters: {
      handler: function() {
        if (this.selectedReport) {
          let hasTag = this.selectedReport.taggings.some(tag =>
            this.appliedTags.includes(tag)
          )
          if (!hasTag) {
            this.setSelectedReport(null)
          }
        }
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.manage--report-group {
  display: none;

  .btn {
    border-radius: 0.25rem;
    padding-top: 2px;
    padding-bottom: 2px;
    width: 4rem;
  }
}

.selectable-list-group {
  .list-group-item {
    &:hover {
      .manage--report-group {
        display: inline;
      }
    }
  }
}
.favorite__star__selected {
  color: #a7ca77;
  display: inline-block;
  padding: 3px;
  vertical-align: middle;
  line-height: 1;
  font-size: 1rem;
  cursor: pointer;
  -webkit-transition: color 0.2s ease-out;
  transition: color 0.2s ease-out;
  -webkit-text-stroke: 0.6px grayscale($color: #686262fb);
}
</style>
